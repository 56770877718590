import {
  Module,
  VuexModule,
  Mutation,
  Action,
  config
} from 'vuex-module-decorators'
import {
  getNetworkStatus,
  getTokenBasicData,
  getValidators,
  getChartData,
  deposit,
  unbond,
  withdraw,
  getTokenWithBalances,
  getCosmosDetails,
  getUserDelegations,
  getUserDelegationRewards,
  getUserUnBondingDelegations,
  getUserReDelegations,
  getTotalProposals,
  delegate,
  reDelegate,
  getGovParams,
  getProposal,
  getProposalsForStatus,
  getSupply,
  proposeSpotMarketLaunch,
  withdrawDelegatorReward,
  getContractBalance,
  getContractBalanceAndAllowance,
  sendAssetToCosmos,
  depositToProposal,
  voteToProposal,
  getTotalProposalsForStatus,
  getInsuranceParams,
  getInsuranceFunds,
  getDenomBalance,
  underwrite,
  requestRedemption,
  setContractAllowance,
  getRedemptions,
  getBank,
  proposePerpetualMarketLaunch,
  proposeExpiryFuturesMarketLaunch,
  getOracles,
  setTokenAllowance
} from 'app/services/staking'
import { getLocale } from 'app/services/network'
import {
  INJ_ID,
  MAINNET_CHAIN_ID,
  UNLIMITED_ALLOWANCE_IN_BASE_UNITS,
  ZERO,
  ZERO_IN_WEI
} from 'app/utils/constants'
import { getWeb3Strategy } from 'app/web3'
import { getUnixTimes } from 'app/utils/time'
import { Web3Exception } from '@injectivelabs/exceptions'
import { BigNumberInBase, BigNumberInWei, sleep } from '@injectivelabs/utils'
import { getStorage } from 'app/singletons/Storage'
import { testnetBackupPromiseCall } from 'app/utils/async'
import { getGasEstimationInfoAsync } from 'app/services/gas'
import { ChainId } from '@injectivelabs/ts-types'
import { LedgerDerivationPathType, Wallet } from '@injectivelabs/web3-strategy'
import { Locale, english } from '~/locales'
import {
  StakingStoreState,
  AccountAddress,
  UiValidator,
  UiBasicData,
  UiNetworkStatus,
  TokenWithBalance,
  CosmosDetails,
  UiDelegator,
  UiUnBondingDelegator,
  UiProposal,
  UiGovParams,
  SpotMarketLaunchProposal,
  ProposalStatusNumber,
  UiReward,
  UiSupplyCoin,
  UiSingleProposal,
  VoteOptionNumber,
  UiReDelegator,
  StakingPagination,
  Pagination,
  UiInsuranceFund,
  UiInsuranceParams,
  UiRedemption,
  UiCoin,
  PerpetualMarketLaunchProposal,
  ExpiryFuturesMarketLaunchProposal,
  UiOracle
} from '~/types'
import { getChainId } from '~/app/services/blockchain'
import { USDT } from '~/app/data/derivatives'

config.rawError = true

const initialState: StakingStoreState = {
  theme: 'dark',
  addresses: [],
  address: '',
  cosmosDetails: {} as CosmosDetails,
  addressConfirmation: '',
  bank: [] as UiCoin[],
  mainnetInj: {} as TokenWithBalance,
  injectiveInj: {} as TokenWithBalance,
  validators: [],
  proposals: [] as UiProposal[],
  totalProposals: 0 as number,
  totalPassedProposals: 0 as number,
  gasPrice: ZERO_IN_WEI,
  yearlyReturnsRatio: 0.05,
  userDelegations: [],
  userDelegationRewards: [],
  govParams: {} as UiGovParams,
  userUnBondingDelegations: [] as UiUnBondingDelegator[],
  userReDelegations: [] as UiReDelegator[],
  networkStatus: {} as UiNetworkStatus,
  basicData: {} as UiBasicData,
  chartData: {} as any,
  supply: [] as UiSupplyCoin[],
  locale: english,
  blockHeight: 0,
  proposal: {} as UiSingleProposal,
  wallet: Wallet.Metamask,
  pagination: {
    proposals: {
      next: null,
      prev: null,
      current: null
    },
    redelegations: {
      next: null,
      prev: null,
      current: null
    },
    unbonding: {
      next: null,
      prev: null,
      current: null
    },
    delegations: {
      next: null,
      prev: null,
      current: null
    }
  } as StakingPagination,
  chainId: MAINNET_CHAIN_ID as ChainId,
  insuranceFunds: [] as UiInsuranceFund[],
  insuranceParams: {} as UiInsuranceParams,
  redemptions: [] as UiRedemption[],
  oracles: [] as UiOracle[]
}

@Module({
  name: 'staking',
  stateFactory: true,
  namespaced: true
})
export default class StakingStore
  extends VuexModule
  implements StakingStoreState
{
  theme: 'dark' | 'light' = initialState.theme
  wallet: Wallet = initialState.wallet

  bank: UiCoin[] = initialState.bank
  networkStatus: UiNetworkStatus = initialState.networkStatus
  basicData: UiBasicData = initialState.basicData
  chartData: any = initialState.chartData

  locale: Locale = initialState.locale
  yearlyReturnsRatio: number = initialState.yearlyReturnsRatio

  mainnetInj: TokenWithBalance = initialState.mainnetInj
  injectiveInj: TokenWithBalance = initialState.injectiveInj

  addresses: AccountAddress[] = initialState.addresses
  cosmosDetails: CosmosDetails = initialState.cosmosDetails
  address: AccountAddress = initialState.address
  addressConfirmation: string = initialState.addressConfirmation
  validators: UiValidator[] = initialState.validators
  gasPrice: BigNumberInWei = initialState.gasPrice
  userDelegations: UiDelegator[] = initialState.userDelegations
  userDelegationRewards: UiReward[] = initialState.userDelegationRewards
  userUnBondingDelegations: UiUnBondingDelegator[] =
    initialState.userUnBondingDelegations

  userReDelegations: UiReDelegator[] = initialState.userReDelegations

  totalProposals: number = initialState.totalProposals
  totalPassedProposals: number = initialState.totalPassedProposals

  blockHeight: number = initialState.blockHeight

  supply: UiSupplyCoin[] = initialState.supply

  govParams: UiGovParams = initialState.govParams
  proposals: UiProposal[] = initialState.proposals
  chainId: number = initialState.chainId

  proposal: UiSingleProposal = initialState.proposal
  pagination: StakingPagination = initialState.pagination

  insuranceFunds: UiInsuranceFund[] = initialState.insuranceFunds
  insuranceParams: UiInsuranceParams = initialState.insuranceParams
  redemptions: UiRedemption[] = initialState.redemptions

  oracles: UiOracle[] = initialState.oracles

  get isUserWalletConnected(): boolean {
    return this.addresses.length > 0 && !!this.address
  }

  get isMainnet(): boolean {
    return this.chainId.toString() === MAINNET_CHAIN_ID.toString()
  }

  @Mutation
  setAppLocale(locale: Locale) {
    this.locale = locale
  }

  @Mutation
  setBlockHeight(blockHeight: number) {
    this.blockHeight = blockHeight
  }

  @Mutation
  setValidators(validators: UiValidator[]) {
    this.validators = validators
  }

  @Mutation
  setGasPrice(gasPrice: BigNumberInWei) {
    this.gasPrice = gasPrice
  }

  @Mutation
  setSupply(supply: UiSupplyCoin[]) {
    this.supply = supply
  }

  @Mutation
  setNetworkStatus(networkStatus: UiNetworkStatus) {
    this.networkStatus = networkStatus
  }

  @Mutation
  setBasicData(basicData: UiBasicData) {
    this.basicData = basicData
  }

  @Mutation
  setTotalProposals({
    totalProposals,
    totalPassedProposals
  }: {
    totalProposals: number
    totalPassedProposals: number
  }) {
    this.totalProposals = totalProposals
    this.totalPassedProposals = totalPassedProposals
  }

  @Mutation
  setChartData(chartData: any) {
    this.chartData = chartData
  }

  @Mutation
  setUserDelegations({
    pagination,
    delegations
  }: {
    pagination: Pagination
    delegations: UiDelegator[]
  }) {
    this.userDelegations = delegations
    this.pagination.delegations = {
      prev: this.pagination.delegations.current,
      current: this.pagination.delegations.next,
      next: pagination
    }
  }

  @Mutation
  setUserDelegationRewards(userDelegationRewards: UiReward[]) {
    this.userDelegationRewards = userDelegationRewards
  }

  @Mutation
  setUserUnBondingDelegations({
    pagination,
    unbondingDelegations
  }: {
    pagination: Pagination
    unbondingDelegations: UiUnBondingDelegator[]
  }) {
    this.userUnBondingDelegations = unbondingDelegations
    this.pagination.unbonding = {
      prev: this.pagination.unbonding.current,
      current: this.pagination.unbonding.next,
      next: pagination
    }
  }

  @Mutation
  setUserReDelegations({
    pagination,
    redelegations
  }: {
    pagination: string | undefined | null
    redelegations: UiReDelegator[]
  }) {
    this.userReDelegations = redelegations
    this.pagination.redelegations = {
      prev: this.pagination.redelegations.current,
      current: this.pagination.redelegations.next,
      next: pagination
    }
  }

  @Mutation
  toggleTheme() {
    this.theme = this.theme === 'dark' ? 'light' : 'dark'
  }

  @Mutation
  setMainnetInj(mainnetInj: TokenWithBalance) {
    this.mainnetInj = mainnetInj
  }

  @Mutation
  setInjectiveInj(injectiveInj: TokenWithBalance) {
    this.injectiveInj = injectiveInj
  }

  @Mutation
  setAddresses(addresses: AccountAddress[]) {
    this.addresses =
      this.addresses.length > 0 ? [...this.addresses, ...addresses] : addresses
  }

  @Mutation
  resetAddresses() {
    this.addresses = []
  }

  @Mutation
  setAddress(address: AccountAddress) {
    this.address = address
  }

  @Mutation
  setGovParams(govParams: UiGovParams) {
    this.govParams = govParams
  }

  @Mutation
  setBank(bank: UiCoin[]) {
    this.bank = bank
  }

  @Mutation
  setChainId(chainId: ChainId) {
    this.chainId = chainId
  }

  @Mutation
  setWallet(wallet: Wallet) {
    this.wallet = wallet
  }

  @Mutation
  setCosmosDetails(details: CosmosDetails) {
    this.cosmosDetails = details
  }

  @Mutation
  resetPagination() {
    this.pagination = { ...initialState.pagination }
  }

  @Mutation
  setProposals({
    pagination,
    proposals
  }: {
    pagination: Pagination
    proposals: UiProposal[]
  }) {
    this.proposals = proposals
    this.pagination.proposals = {
      prev: this.pagination.proposals.current,
      current: this.pagination.proposals.next,
      next: pagination
    }
  }

  @Mutation
  setProposal(proposal: UiSingleProposal) {
    this.proposal = proposal
  }

  @Mutation
  setAddressConfirmation(confirmation: string) {
    this.addressConfirmation = confirmation
  }

  @Mutation
  setInsuranceFunds(insuranceFunds: UiInsuranceFund[]) {
    this.insuranceFunds = insuranceFunds
  }

  @Mutation
  setInsuranceParams(params: UiInsuranceParams) {
    this.insuranceParams = params
  }

  @Mutation
  setRedemptions(redemptions: UiRedemption[]) {
    this.redemptions = redemptions
  }

  @Mutation
  setOracles(oracles: UiOracle[]) {
    this.oracles = oracles
  }

  @Mutation
  logout() {
    getStorage().clear()
    window.location.reload()
  }

  @Action
  async init() {
    this.setAppLocale(getLocale() || english)
    this.setGasPrice(await getGasEstimationInfoAsync())
    this.setNetworkStatus(await getNetworkStatus())
    this.setBasicData(await getTokenBasicData(INJ_ID))
    this.setGovParams(await getGovParams())
    this.setSupply(await getSupply())
    this.setOracles(await getOracles())
    this.setChainId((await getChainId()) || MAINNET_CHAIN_ID)
    await this.getTokenBalances()
  }

  @Action
  async setTokenAllowance({ isUnlocked }: TokenWithBalance) {
    const { address, gasPrice } = this

    if (!address) {
      return
    }

    const amount = new BigNumberInWei(
      isUnlocked ? ZERO : UNLIMITED_ALLOWANCE_IN_BASE_UNITS
    )
    await setTokenAllowance({
      address,
      amount,
      gasPrice
    })

    await testnetBackupPromiseCall(() => this.getTokenBalances())
  }

  @Action
  async connect(wallet: Wallet = Wallet.Metamask) {
    this.setWallet(wallet)

    const web3Strategy = getWeb3Strategy(wallet)
    const addresses = await web3Strategy.getAddresses()

    if (addresses.length === 0) {
      throw new Web3Exception('There are no addresses linked in this wallet.')
    }

    const [address] = addresses
    const confirmation = await web3Strategy.confirm(address)

    web3Strategy.onAccountChange(() => {
      getStorage().clear()
      window.location.reload()
    })
    web3Strategy.onChainChange(() => {
      getStorage().clear()
      window.location.reload()
    })

    this.setAddressConfirmation(confirmation)
    this.setAddresses(addresses)
    this.setAddress(address)
    this.setCosmosDetails(await getCosmosDetails(address))
  }

  @Action
  async getUserAddresses(wallet: Wallet = Wallet.Metamask) {
    this.setWallet(wallet)

    const web3Strategy = getWeb3Strategy(wallet)
    const addresses = await web3Strategy.getAddresses()

    if (addresses.length === 0) {
      throw new Web3Exception('There are no addresses linked in this wallet.')
    }

    web3Strategy.onAccountChange(() => {
      getStorage().clear()
      window.location.reload()
    })
    web3Strategy.onChainChange(() => {
      getStorage().clear()
      window.location.reload()
    })

    this.setAddresses(addresses)
  }

  @Action
  async confirmUserAddress(address: string) {
    const web3Strategy = getWeb3Strategy()
    const confirmation = await web3Strategy.confirm(address)

    this.setAddressConfirmation(confirmation)
    this.setAddress(address)
    this.setCosmosDetails(await getCosmosDetails(address))
  }

  @Action
  async getTokenBalances() {
    const { address, cosmosDetails, isUserWalletConnected } = this

    if (!address || !cosmosDetails.cosmosAddress || !isUserWalletConnected) {
      return
    }

    const { mainnetInj, injectiveInj } = await getTokenWithBalances(
      address,
      cosmosDetails.cosmosAddress
    )

    this.setMainnetInj(mainnetInj)
    this.setInjectiveInj(injectiveInj)
    this.setBank(await getBank(cosmosDetails.cosmosAddress))
  }

  @Action
  async getDenomBalance(denom: string) {
    const { address, cosmosDetails, isUserWalletConnected } = this

    if (!address || !cosmosDetails.cosmosAddress || !isUserWalletConnected) {
      return ZERO_IN_WEI
    }

    return await getDenomBalance(cosmosDetails.cosmosAddress, denom)
  }

  @Action
  async fetchValidators() {
    this.setValidators(await getValidators())
    // this.subscribeToBlockHeightUpdate()
    await this.getTokenBalances()
  }

  @Action
  async fetchDashboard() {
    this.setNetworkStatus(await getNetworkStatus())
    this.setBasicData(await getTokenBasicData(INJ_ID))
    await this.getTokenBalances()

    const times = getUnixTimes(24 * 60, 'minute', 'hour')
    this.setChartData(await getChartData(INJ_ID, times[0], times[1]))
  }

  @Action
  async fetchProposals() {
    const proposals = await getTotalProposals()
    const passedProposals = await getTotalProposalsForStatus(
      ProposalStatusNumber.PROPOSAL_STATUS_PASSED
    )

    this.setTotalProposals({
      totalPassedProposals: passedProposals || 0,
      totalProposals: proposals || 0
    })
  }

  @Action
  async fetchProposal(proposalId: number) {
    this.setProposal(await getProposal(proposalId))
  }

  @Action
  async fetchProposalsForStatus({
    status,
    pagination
  }: {
    status: ProposalStatusNumber
    pagination: Pagination
  }) {
    this.setProposals(await getProposalsForStatus(status, pagination))
  }

  @Action
  async fetchNetworkStatus() {
    this.setNetworkStatus(await getNetworkStatus())
  }

  @Action
  async fetchWallet() {
    const { address, cosmosDetails, isUserWalletConnected } = this

    if (!address || !cosmosDetails.cosmosAddress || !isUserWalletConnected) {
      return
    }

    await this.getTokenBalances()
    this.setNetworkStatus(await getNetworkStatus())
    this.setValidators(await getValidators())
    this.setBasicData(await getTokenBasicData(INJ_ID))
    this.setCosmosDetails(await getCosmosDetails(address))
    this.setUserDelegations(
      await getUserDelegations(
        cosmosDetails.cosmosAddress,
        this.pagination.delegations.next
      )
    )
    this.setUserDelegationRewards(
      await getUserDelegationRewards(cosmosDetails.cosmosAddress)
    )
    this.setUserUnBondingDelegations(
      await getUserUnBondingDelegations(
        cosmosDetails.cosmosAddress,
        this.pagination.unbonding.next
      )
    )
    this.setUserReDelegations(
      await getUserReDelegations(
        cosmosDetails.cosmosAddress,
        this.pagination.redelegations.next
      )
    )
  }

  @Action
  async fetchInsuranceFunds() {
    const { address, cosmosDetails, isUserWalletConnected } = this

    if (!address || !cosmosDetails.cosmosAddress || !isUserWalletConnected) {
      return
    }

    this.setInsuranceParams(await getInsuranceParams())
    this.setInsuranceFunds(await getInsuranceFunds())
    this.setRedemptions(
      await getRedemptions({ address: cosmosDetails.cosmosAddress })
    )
    this.setBank(await getBank(cosmosDetails.cosmosAddress))
  }

  @Action
  async deposit({
    amount,
    destinationAddress
  }: {
    amount: BigNumberInBase
    destinationAddress: string
  }) {
    const { address, gasPrice, isUserWalletConnected, isMainnet } = this

    if (!address || !isUserWalletConnected) {
      return
    }

    if (!isMainnet) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await deposit({
      address,
      destinationAddress,
      gasPrice,
      amount: amount.toWei()
    })

    await testnetBackupPromiseCall(() => this.getTokenBalances())
  }

  @Action
  async withdraw({
    amount,
    destinationAddress
  }: {
    amount: BigNumberInBase
    destinationAddress: string
  }) {
    const { address, cosmosDetails, wallet, isUserWalletConnected, isMainnet } =
      this

    if (!address || !isUserWalletConnected) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await withdraw({
      address,
      destinationAddress,
      cosmosAddress: cosmosDetails.cosmosAddress,
      amount: amount.toWei()
    })

    await testnetBackupPromiseCall(() => this.getTokenBalances())
  }

  @Action
  async delegate({
    amount,
    validatorAddress
  }: {
    amount: BigNumberInBase
    validatorAddress: string
  }) {
    const { address, cosmosDetails, wallet, isUserWalletConnected, isMainnet } =
      this

    if (!address || !isUserWalletConnected) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await delegate({
      address,
      cosmosAddress: cosmosDetails.cosmosAddress,
      validatorAddress,
      amount: amount.toWei()
    })

    await testnetBackupPromiseCall(() => this.getTokenBalances())
    await testnetBackupPromiseCall(() => this.fetchValidators())
  }

  @Action
  async reDelegate({
    destinationValidatorAddress,
    sourceValidatorAddress,
    amount,
    denom
  }: {
    destinationValidatorAddress: string
    sourceValidatorAddress: string
    amount: BigNumberInBase
    denom: string
  }) {
    const { address, cosmosDetails, wallet, isUserWalletConnected, isMainnet } =
      this

    if (!address || !isUserWalletConnected) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await reDelegate({
      address,
      denom,
      destinationValidatorAddress,
      sourceValidatorAddress,
      amount: amount.toWei(),
      cosmosAddress: cosmosDetails.cosmosAddress
    })

    await testnetBackupPromiseCall(() => this.fetchWallet())
  }

  @Action
  async underwrite({
    marketId,
    denom,
    amount
  }: {
    denom: string
    marketId: string
    amount: BigNumberInBase
  }) {
    const { address, cosmosDetails, wallet, isUserWalletConnected, isMainnet } =
      this

    if (!address || !isUserWalletConnected) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await underwrite({
      address,
      denom,
      marketId,
      cosmosAddress: cosmosDetails.cosmosAddress,
      amount: amount.toWei(USDT.decimals)
    })

    await testnetBackupPromiseCall(() => this.fetchInsuranceFunds())
  }

  @Action
  async requestRedemption({
    marketId,
    denom,
    amount
  }: {
    denom: string
    marketId: string
    amount: BigNumberInBase
  }) {
    const { address, cosmosDetails, wallet, isUserWalletConnected, isMainnet } =
      this

    if (!address || !isUserWalletConnected) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await requestRedemption({
      address,
      denom,
      marketId,
      cosmosAddress: cosmosDetails.cosmosAddress,
      amount: new BigNumberInWei(amount)
    })

    await testnetBackupPromiseCall(() => this.fetchInsuranceFunds())
  }

  @Action
  async unbond({
    validatorAddress,
    amount
  }: {
    validatorAddress: string
    amount: BigNumberInBase
  }) {
    const { address, cosmosDetails, wallet, isUserWalletConnected, isMainnet } =
      this

    if (!address || !isUserWalletConnected) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await unbond({
      amount: amount.toWei(),
      address,
      validatorAddress,
      cosmosAddress: cosmosDetails.cosmosAddress
    })

    await testnetBackupPromiseCall(() => this.fetchWallet())
  }

  @Action
  async withdrawDelegatorReward(validatorAddress: string) {
    const { address, cosmosDetails, wallet, isUserWalletConnected, isMainnet } =
      this

    if (!address || !isUserWalletConnected) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await withdrawDelegatorReward({
      address,
      validatorAddress,
      cosmosAddress: cosmosDetails.cosmosAddress
    })

    await testnetBackupPromiseCall(() => this.fetchWallet())
  }

  @Action
  async depositToProposal({
    proposalId,
    amount
  }: {
    proposalId: number
    amount: BigNumberInBase
  }) {
    const { address, wallet, cosmosDetails, isUserWalletConnected, isMainnet } =
      this

    if (!address || !isUserWalletConnected) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await depositToProposal({
      amount: amount.toWei(),
      address,
      cosmosAddress: cosmosDetails.cosmosAddress,
      proposalId
    })

    await sleep(3000)
    await this.fetchProposal(proposalId)
  }

  @Action
  async voteToProposal({
    proposalId,
    vote
  }: {
    proposalId: number
    vote: VoteOptionNumber
  }) {
    const { address, wallet, cosmosDetails, isUserWalletConnected, isMainnet } =
      this

    if (!address || !isUserWalletConnected) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await voteToProposal({
      vote,
      address,
      cosmosAddress: cosmosDetails.cosmosAddress,
      proposalId
    })

    await sleep(3000)
    await this.fetchProposal(proposalId)
  }

  @Action
  async proposeSpotMarketLaunch({
    market,
    deposit,
    denom = 'inj'
  }: {
    denom?: string
    market: SpotMarketLaunchProposal
    deposit: BigNumberInBase
  }) {
    const { address, cosmosDetails, wallet, isMainnet } = this

    if (!address) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await proposeSpotMarketLaunch({
      market,
      address,
      deposit: {
        denom,
        amount: deposit.toWei().toFixed()
      },
      cosmosAddress: cosmosDetails.cosmosAddress
    })

    await this.fetchProposals()
  }

  @Action
  async proposePerpetualMarketLaunch({
    market,
    deposit,
    denom = 'inj'
  }: {
    denom?: string
    market: PerpetualMarketLaunchProposal
    deposit: BigNumberInBase
  }) {
    const { address, cosmosDetails, wallet, isMainnet } = this

    if (!address) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await proposePerpetualMarketLaunch({
      market,
      address,
      deposit: {
        denom,
        amount: deposit.toWei().toFixed()
      },
      cosmosAddress: cosmosDetails.cosmosAddress
    })

    await this.fetchProposals()
  }

  @Action
  async proposeExpiryFuturesMarketLaunch({
    market,
    deposit,
    denom = 'inj'
  }: {
    denom?: string
    market: ExpiryFuturesMarketLaunchProposal
    deposit: BigNumberInBase
  }) {
    const { address, cosmosDetails, wallet, isMainnet } = this

    if (!address) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await proposeExpiryFuturesMarketLaunch({
      market,
      address,
      deposit: {
        denom,
        amount: deposit.toWei().toFixed()
      },
      cosmosAddress: cosmosDetails.cosmosAddress
    })

    await this.fetchProposals()
  }

  @Action
  async sendAssetToCosmos({
    token,
    amount
  }: {
    token: TokenWithBalance
    amount: BigNumberInBase
  }) {
    const { address, cosmosDetails, gasPrice, wallet, isMainnet } = this

    if (!address || !cosmosDetails.cosmosAddress) {
      return
    }

    if (!isMainnet && wallet === Wallet.Metamask) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    await sendAssetToCosmos({
      address,
      token,
      gasPrice,
      destinationAddress: cosmosDetails.cosmosAddress,
      amount: amount.toWei(token.decimals)
    })

    this.setSupply(await getSupply())
  }

  @Action
  async getContractBalance(contractAddress: string) {
    const { address, gasPrice, isMainnet } = this

    if (!address) {
      return
    }

    if (!isMainnet) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    return await getContractBalance({
      address,
      gasPrice,
      contractAddress
    })
  }

  @Action
  async getContractBalanceAndAllowance(contractAddress: string) {
    const { address, gasPrice, isMainnet } = this

    if (!address) {
      return
    }

    if (!isMainnet) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    return await getContractBalanceAndAllowance({
      address,
      gasPrice,
      contractAddress
    })
  }

  @Action
  async setContractAllowance(contractAddress: string) {
    const { address, gasPrice, isMainnet } = this

    if (!address) {
      return
    }

    if (!isMainnet) {
      throw new Error(
        'Please switch to Ethereum Mainnet to perform this action.'
      )
    }

    return await setContractAllowance({
      address,
      gasPrice,
      contractAddress
    })
  }

  @Action
  async setDervivationPathTypeForLedgerWeb3Strategy(
    type: LedgerDerivationPathType
  ) {
    this.resetAddresses()
    await Promise.resolve(
      getWeb3Strategy().setStrategyOptions({ derivationPathType: type })
    )
  }
}
