import { Web3Strategy, Wallet } from '@injectivelabs/web3-strategy'
import { ChainId } from '@injectivelabs/ts-types'
import { MAINNET_CHAIN_ID, RPC_POLING_INTERVAL } from 'app/utils/constants'
import { app } from '../singletons/App'
import { getStorage } from '../singletons/Storage'

let web3Strategy: Web3Strategy

export const getRpcUrlsForChainIds = (): Record<ChainId, string> => {
  return {
    [ChainId.Ganache]: 'http://localhost:8545',
    [ChainId.HardHat]: 'http://localhost:8545',
    [ChainId.Kovan]: ``,
    [ChainId.Mainnet]: `https://eth-mainnet.alchemyapi.io/v2/${process.env.APP_ALCHEMY_KEY}`,
    [ChainId.Injective]: '',
    [ChainId.Rinkeby]: '',
    [ChainId.Ropsten]: ''
  }
}

export const getRpcWsUrlsForChainIds = (): Record<ChainId, string> => {
  return {
    [ChainId.Ganache]: 'ws://localhost:1318',
    [ChainId.HardHat]: 'ws://localhost:1318',
    [ChainId.Kovan]: ``,
    [ChainId.Mainnet]: `wss://eth-mainnet.ws.alchemyapi.io/v2/${process.env.APP_ALCHEMY_KEY}`,
    [ChainId.Injective]: '',
    [ChainId.Rinkeby]: '',
    [ChainId.Ropsten]: ''
  }
}

export const initWeb3Strategy = (wallet: Wallet) => {
  web3Strategy = new Web3Strategy({
    wallet,
    chainId: parseInt(MAINNET_CHAIN_ID.toString()),
    options: {
      wsRpcUrls: getRpcWsUrlsForChainIds(),
      rpcUrls: getRpcUrlsForChainIds(),
      pollingInterval: RPC_POLING_INTERVAL,
      blockTracker: false
    }
  })

  web3Strategy.onAccountChange(() => {
    getStorage().clear()
    window.location.reload()
  })

  web3Strategy.onChainChange(() => {
    getStorage().clear()
    window.location.reload()
  })
}

export const getWeb3Strategy = (wallet?: Wallet) => {
  if (!web3Strategy) {
    initWeb3Strategy(wallet || app.wallet)
  }

  return web3Strategy
}
