import { alchemyApi } from '../singletons/AlchemyApi'
import { getDerivatives } from '../classes/Derivative'

export const fetchTokenMetaData = async (denom: string) => {
  const $derivatives = getDerivatives()
  const address = denom.startsWith('peggy') ? denom.replace('peggy', '') : denom
  const contractAddress =
    address.toLowerCase() === 'inj'
      ? $derivatives.getTokenBySymbol('inj').address
      : address

  return await alchemyApi.fetchTokenMetadata(contractAddress)
}
