





















































































































































































import Vue from 'vue'
import { StakingStoreModule } from 'app/store/accessor'
import Logo from '~/components/layouts/logo.vue'
import MobileMenu from '~/components/layouts/mobile-menu.vue'
import WalletDropdown from '~/components/layouts/wallet.vue'

export default Vue.extend({
  components: {
    Logo,
    MobileMenu,
    WalletDropdown
  },

  computed: {
    isUserWalletConnected(): boolean {
      return StakingStoreModule.isUserWalletConnected
    }
  }
})
