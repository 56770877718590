











































import Vue from 'vue'
import { StakingStoreModule } from 'app/store/accessor'
import { Status, StatusType } from '@injectivelabs/utils'
import ThemeSwitcher from '~/components/elements/theme.vue'
import Header from '~/components/layouts/header.vue'
import Footer from '~/components/layouts/footer.vue'
import WithLoading from '~/components/elements/with-loading.vue'

export default Vue.extend({
  components: {
    'v-theme': ThemeSwitcher,
    'v-header': Header,
    'v-footer': Footer,
    WithLoading
  },

  data() {
    return {
      status: new Status(StatusType.Loading)
    }
  },

  computed: {
    isUserWalletConnected(): boolean {
      return StakingStoreModule.isUserWalletConnected
    },

    theme(): 'dark' | 'light' {
      return StakingStoreModule.theme
    }
  },

  watch: {
    theme(newValue) {
      if (newValue === 'dark') {
        document.body.classList.add('dark')
      } else {
        document.body.classList.remove('dark')
      }
    }
  },

  mounted() {
    StakingStoreModule.init().then(() => {
      this.$nextTick(() => {
        this.status.setCompleted()
      })
    })

    if (this.theme === 'dark') {
      document.body.classList.add('dark')
    }
  },

  methods: {
    onThemeSwitch() {
      StakingStoreModule.toggleTheme()
    }
  }
})
