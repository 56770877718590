import { BigNumberInWei, HttpClient } from '@injectivelabs/utils'
import { DEFAULT_GAS_PRICE, GWEI_IN_WEI } from '../utils/constants'

export interface GasInfo {
  gasPriceInWei: BigNumberInWei
  estimatedTimeMs: number
}

export interface EthGasStationResult {
  data: {
    average: number
    fastestWait: number
    fastWait: number
    fast: number
    safeLowWait: number
    blockNum: number
    avgWait: number
    // eslint-disable-next-line camelcase
    block_time: number
    speed: number
    fastest: number
    safeLow: number
  }
}

export const getGasEstimationInfoAsync = async (): Promise<BigNumberInWei> => {
  let fetchedAmount

  try {
    fetchedAmount = await fetchGasInWeiAsync()
  } catch (e) {
    fetchedAmount = null
  }

  return fetchedAmount || DEFAULT_GAS_PRICE
}

export const fetchGasInWeiAsync = async (): Promise<BigNumberInWei> => {
  const httpClient = new HttpClient('https://ethgasstation.info/json')
  const response = (await httpClient.get(
    `ethgasAPI.json`
  )) as EthGasStationResult

  const gasPriceInGwei = new BigNumberInWei(
    response && response.data ? response.data.fastest / 10 : DEFAULT_GAS_PRICE
  )

  return gasPriceInGwei.multipliedBy(GWEI_IN_WEI)
}
