














































































import Vue from 'vue'
import { directive as onClickaway } from 'vue-clickaway'

export default Vue.extend({
  components: {
    //
  },

  directives: {
    onClickaway
  },

  data() {
    return {
      isMenuOpen: false
    }
  },

  methods: {
    closeMenu() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false
      }
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },

    goToWallet() {
      this.$router.push({ name: 'wallet' })
      this.closeMenu()
    },

    goToValidators() {
      this.$router.push({ name: 'validators' })
      this.closeMenu()
    },

    goToInsuranceFund() {
      this.$router.push({ name: 'insurance-fund' })
      this.closeMenu()
    },

    goToGovernance() {
      this.$router.push({ name: 'governance' })
      this.closeMenu()
    }
  }
})
