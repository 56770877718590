import {
  MAINNET_CHAIN_ID,
  UI_DEFAULT_DECIMAL_PLACES,
  ZERO_ADDRESS
} from 'app/utils/constants'
import { Token, TokenAddressObject, TokenWithAddresses } from '~/types'

const baseTokens = [
  {
    symbol: 'INJ',
    name: 'Injective',
    icon: '/icons/inj-2.svg',
    decimals: 18,
    displayDecimals: 0,
    address: '0xe28b3b32b6c345a34ff64674606124dd5aceca30',
    addresses: {
      '1': '0xe28b3b32b6c345a34ff64674606124dd5aceca30',
      '42': ZERO_ADDRESS,
      '888': ZERO_ADDRESS
    }
  }
]

export const includedInBalanceList = ['INJ']

const derivatives = [
  {
    symbol: 'USDT',
    name: 'USDT',
    icon: '/icons/usdt.svg',
    decimals: 6,
    displayDecimals: 4,
    addresses: {
      '1': '0xdac17f958d2ee523a2206206994597c13d831ec7',
      '42': '0x69efCB62D98f4a6ff5a0b0CFaa4AAbB122e85e08'
    }
  },
  ...baseTokens
]

export const transformedDerivatives = derivatives
  .filter(
    ({ addresses }: { addresses: TokenAddressObject }) =>
      addresses[MAINNET_CHAIN_ID]
  )
  .map(
    ({
      symbol,
      addresses,
      decimals,
      name,
      icon,
      displayDecimals,
      quoteTokenDisplayDecimals
    }: TokenWithAddresses): Token => ({
      symbol,
      decimals,
      name,
      icon,
      addresses,
      address: addresses[MAINNET_CHAIN_ID],
      displayDecimals:
        displayDecimals || displayDecimals === 0
          ? displayDecimals
          : UI_DEFAULT_DECIMAL_PLACES,
      quoteTokenDisplayDecimals
    })
  )

export const USDT = {
  symbol: 'USDT',
  name: 'USDT',
  icon: '/icons/usdt.svg',
  decimals: 6,
  displayDecimals: 4,
  addresses: {
    '1': '0xdac17f958d2ee523a2206206994597c13d831ec7',
    '42': '0x69efCB62D98f4a6ff5a0b0CFaa4AAbB122e85e08'
  },
  address: '0xdac17f958d2ee523a2206206994597c13d831ec7'
}
