import { NETWORK_ID, MAINNET_CHAIN_ID } from 'app/utils/constants'
import { getWeb3Strategy } from 'app/web3'

export const getNetworkId = async (): Promise<number | undefined> => {
  try {
    const networkId = await getWeb3Strategy().getNetworkId()

    return parseInt(networkId)
  } catch (e) {
    return parseInt(MAINNET_CHAIN_ID.toString())
  }
}

export const getChainId = async (): Promise<number | undefined> => {
  try {
    const chainId = await getWeb3Strategy().getChainId()

    return parseInt(chainId)
  } catch (e) {
    return parseInt(MAINNET_CHAIN_ID.toString())
  }
}

export const isWeb3Connected = (): boolean => {
  return getWeb3Strategy().isWeb3Connected()
}

export const isOnCorrectNetwork = async (): Promise<boolean> => {
  const networkId = await getNetworkId()
  const chainId = await getChainId()

  return (
    chainId === parseInt(MAINNET_CHAIN_ID.toString()) &&
    networkId === parseInt(NETWORK_ID.toString())
  )
}

export const getWalletAddresses = async (): Promise<string[]> => {
  return await getWeb3Strategy().getStrategy().getAddresses()
}

export const waitTransactionSuccess = async (txHash: string): Promise<any> => {
  return await getWeb3Strategy().getTransactionReceipt(txHash)
}

export const getRevertReason = async (error: any): Promise<string> => {
  if (!error.message) {
    return error
  }

  const errorMessagePrefix = 'Transaction has been reverted by the EVM:'

  if (!error.message.includes(errorMessagePrefix)) {
    return error.message
  }

  const revertObject = error.message.replace(errorMessagePrefix, '')

  let parsed
  try {
    parsed = JSON.parse(revertObject)
  } catch (e) {
    return error.message
  }

  if (!parsed) {
    return error.message
  }

  if (!parsed.transactionHash) {
    return error.message
  }

  return await Promise.resolve(error)

  /*
  const getRevertReason = require('~/assets/js/revertReason')

  try {
    return await getRevertReason(parsed.transactionHash, app.appRpcUrl)
  } catch (e) {
    return e.message
  } */
}
