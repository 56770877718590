import { HttpClient } from '@injectivelabs/utils'
import { app } from './App'
import { BasicData, NetworkStatus } from '~/types'

export class HttpConsumer {
  private httpClient: HttpClient

  constructor(baseUrl: string) {
    this.httpClient = new HttpClient(baseUrl)
  }

  async getNetworkStatus(): Promise<{ data: NetworkStatus }> {
    return await this.httpClient.get(`${app.appUrlEndpoint.explorerUrl}/status`)
  }

  async getTokenBasicData(id: string): Promise<{ data: BasicData }> {
    return await this.httpClient.get(
      `${app.appUrlEndpoint.explorerUrl}/market?id=${id}`
    )
  }

  getMarketChartRange(
    id = 'injective-protocol',
    from: number,
    to: number
  ): Promise<{
    // eslint-disable-next-line camelcase
    data: { prices: [[number, number]]; total_volumes: [[number, number]] }
  }> {
    const endpoint = 'https://api.coingecko.com/api/v3'
    const getMarketRange = (
      id = 'injective-protocol',
      from: number,
      to: number
    ) => `/coins/${id}/market_chart/range?vs_currency=usd&from=${from}&to=${to}`

    return this.httpClient.get(`${endpoint}${getMarketRange(id, from, to)}`)
  }
}

export const httpConsumer = new HttpConsumer(app.appUrlEndpoint.baseUrl)
