export interface ContractAddresses {
  futures: string
  peggy: string
  injective: string
}

export interface ContractInstance {
  //
}

export enum FuturesEvents {}
//

export enum PriceFeederEvents {}
//

export type FuturesEventSignatures =
  | 'FuturesPosition'
  | 'FuturesClose'
  | 'SetFunding'
